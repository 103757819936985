var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"section-topic"},[_c('div',{staticClass:"title-header"},[_c('h1',[_vm._v(_vm._s(_vm.$t("absence.title")))])])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('div',{staticClass:"section-action"},[_c('div',{staticClass:"btn-new-create-action"},[(_vm.listAbsence.length <= 1)?_c('v-btn',{staticClass:"btn-create",on:{"click":function($event){return _vm.onCreate()}}},[_vm._v(" "+_vm._s(_vm.$t("absence.create"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"section-form-content"},[_c('div',{staticClass:"block-content"},[(_vm.listAbsence.length > 0)?_c('table',{staticClass:"table table-view"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-start"},[_vm._v(" "+_vm._s(_vm.$t("absence.no"))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("absence.name"))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("absence.type")))]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("absence.value"))+" ")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.listAbsence),function(item,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(" "+_vm._s(_vm.pagination.current_page * 10 - 10 + idx + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.per_time)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$t("absence." + item.type))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$helpers.numberFormatter(item.value))+" ")]),_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-ellipsis-v btn-ellipsis"})])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-edit",on:{"click":function($event){return _vm.onEdit(item.id)}}},[_vm._v("Edit")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-delete",on:{"click":function($event){return _vm.onDeleted(item.id)}}},[_vm._v("Delete")])],1)],1)],1)],1)])}),0)]):_c('defaultTableNoResult'),(_vm.pagination.total_pages > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":_vm.fetchAbsence}}):_vm._e(),_c('ModalEdit',{attrs:{"fullscreen":"","title":"ແກ້ໄຂຂໍ້ມູນ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('EditAbsence',{attrs:{"absence":_vm.absence},on:{"close":close,"success":_vm.fetchAbsence}})]}}])}),_c('ModalDelete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('DeleteAbsence',{attrs:{"absenceId":_vm.absenceId},on:{"close":close,"success":_vm.fetchAbsence}})]}}])}),(_vm.isLoading)?_c('Loading'):_vm._e()],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }