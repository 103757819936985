<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                dense
                :label="$t('absence.name')"
                v-model="absence.per_time"
                :hint="`${server_errors.per_time}`"
                persistent-hint
                type="number"
                class="input-number"
                @keydown.enter="focusNext"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <vuetify-money
                dense
                :outlined="true"
                :options="$store.state.formatSalaryNumber"
                :clearable="true"
                :label="$t('absence.value')"
                v-model="absence.value"
                :hint="`${server_errors.value}`"
                persistent-hint
                @keydown.enter="focusNext"
              />
            </v-col>
            <v-col cols="8" class="mt-5">
              <v-btn
                class="btn-save-change"
                @click="ValidateForm"
                :loading="btnLoading"
              >
                {{ $t("absence.updated") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    absence: {},
  },
  data() {
    return {
      btnLoading: false,
      data: {
        per_time: "",
        value: "",
      },

      server_errors: {
        per_time: "",
        value: "",
      },
    };
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },

    saveChange() {
      this.$axios
        .put(`company/edit/payroll/absence`, {
          id: this.absence.id,
          per_time: this.absence.per_time,
          value: this.absence.value,
        })
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_EditItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.btnLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
  },
  created() {
    console.log(this.absence);
  },
};
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 600px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 100px;
  }
}

.text-date-label {
  color: #6e6d6d;
  font-weight: normal;

  .text-show-error {
    font-size: 12px;
    color: red;
  }
}
</style>
